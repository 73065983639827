import * as React from 'react'
import * as Container from "../../container/container";
import { StaticImage } from "gatsby-plugin-image";

const Novel = (key) => {
    return (
        <Container.Fullwidth key={key.toString() + 'slider'}>
            <Container.Novel id={key} sliderTitleId="nodelTitle">
                <div className="bg-grey">
                    <p>1952 Passau</p>
                    <h2>Passau zu Beginn der Europäischen Wochen im Spätsommer 1952 …</h2>
                    <StaticImage src="../../../../static/images/Passau/Novel/Szene_1_Bild_1.png" placeholder="none"  alt=""/>
                </div>
                <StaticImage src="../../../../static/images/Passau/Novel/Szene_1_Bild_2.png" placeholder="none"  alt=""/>
                <div className="bg-grey">
                    <h2>Vor der Aufführung von „Die Hochzeit des Figaro“ …</h2>
                    <StaticImage src="../../../../static/images/Passau/Novel/Szene_2_Bild_1.png" placeholder="none"  alt=""/>
                </div>
                <StaticImage src="../../../../static/images/Passau/Novel/Szene_2_Bild_2.png" placeholder="none"  alt=""/>
                <div className="bg-grey">
                    <h2>Die Passauer Nibelungenhalle …</h2>
                    <StaticImage src="../../../../static/images/Passau/Novel/Szene_3_Bild_1.png" placeholder="none"  alt=""/>
                </div>
                <StaticImage src="../../../../static/images/Passau/Novel/Szene_3_Bild_2.png" placeholder="none"  alt=""/>
            </Container.Novel>
        </Container.Fullwidth>
    )
}

export default Novel

