import * as React from 'react'
import * as Container from "../../container/container";
import {StaticImage} from "gatsby-plugin-image";

const TextBlock4Parallax = (key) => {
    return (
        <Container.Part background="bg-img parallax-bg parallax-passau" key={key}>
            <Container.Row>
                <div className="col-6">
                    <figure className="lightbox">
                        <a className="glightbox" data-gallery="gallery2"
                           href={"/images/Passau/passau_bild_0017.jpg"}
                           data-glightbox="title: Landtagsbeschluss zu Schaffung eines vereinten Europas, 23. September 1948;
                            description: .custom-desc1_9; descPosition: bottom;">
                            <StaticImage src="../../../../static/images/Passau/passau_bild_0017.jpg" width={335}
                                         layout="fixed" placeholder="none"
                                         alt="Landtagsbeschluss zur Schaffung eines vereinten Europas, 23. September 1948"/>
                        </a>
                        <div className="glightbox-desc custom-desc1_9">
                            <p> Landtagsbeschluss zur Schaffung eines vereinten Europas, 23. September 1948 </p>
                            <p>
                                1948 forderte der Bayerische Landtag in einem Entschließungsantrag die Staatsregierung
                                auf, die Gründung der „Vereinigten Staaten von Europa“ mit allen Möglichkeiten zu
                                unterstützen.
                            </p>
                            <p className="copy">Abb. Verhandlungen des Bayerischen Landtags, Stenographische Berichte,
                                1. Wahlperiode, Beilage 1855</p>
                        </div>
                        <figcaption>
                            Landtagsbeschluss zur Schaffung eines vereinten Europas, 23. September 1948
                            <span className="description">
                                1948 forderte der Bayerische Landtag in einem Entschließungsantrag die Staatsregierung
                                auf, die Gründung der „Vereinigten Staaten von Europa“ mit allen Möglichkeiten zu
                                unterstützen.
                            </span>
                        </figcaption>
                    </figure>
                </div>
                <div className="col-6">
                    <figure className="lightbox">
                        <a className="glightbox" data-gallery="gallery2"
                           href={"/images/Passau/passau_bild_0016.jpg"}
                           data-glightbox="title: Informationsblatt der Europa-Union Bayern, August 1947;
                            description: .custom-desc1_10; descPosition: bottom;">
                            <StaticImage src="../../../../static/images/Passau/passau_bild_0016.jpg" width={335}
                                         layout="fixed" placeholder="none"
                                         alt="Informationsblatt der Europa-Union Bayern, August 1947"/>
                        </a>
                        <div className="glightbox-desc custom-desc1_10">
                            <p>
                                Ende 1946 sammelten sich verschiedene proeuropäische Akteure in der Europa-Union, die
                                1947 zu einem ersten deutschlandweiten Kongress zusammenkam. Kurz darauf bildete sich
                                auch ein bayerischer Landesverband.
                            </p>
                            <p className="copy">Abb. Bayerisches Hauptstaatsarchiv, BayHStA Stk 16609</p>
                        </div>
                        <figcaption>
                            Informationsblatt der Europa-Union Bayern, August 1947
                            <span className="description">
                                Ende 1946 sammelten sich verschiedene proeuropäische Akteure in der Europa-Union, die
                                1947 zu einem ersten deutschlandweiten Kongress zusammenkam. Kurz darauf bildete sich
                                auch ein bayerischer Landesverband.
                            </span>
                        </figcaption>
                    </figure>
                </div>
            </Container.Row>
            <Container.More move="true" lessText="weniger lesen" moreText="weiter lesen">
                <Container.Row>
                    <div className="col-6">
                        <figure className="lightbox">
                            <a className="glightbox" data-gallery="gallery2"
                               href={"/images/Passau/passau_bild_0014_15.jpg"}
                               data-glightbox="title: Veranstaltungsprogramm der Europa-Union Bayern, 1948;
                            description: .custom-desc1_11; descPosition: bottom;">
                                <StaticImage src="../../../../static/images/Passau/passau_bild_0014.jpg" width={335}
                                             layout="fixed" placeholder="none"
                                             alt="Veranstaltungsprogramm der Europa-Union Bayern, 1948"/>
                            </a>
                            <div className="glightbox-desc custom-desc1_11">
                                <p>
                                    Mit verschiedenen Vorträgen und Veranstaltungen verbreitete die Europa-Union die
                                    europäische Idee in der Bevölkerung. Finanzielle Unterstützung dafür kam auch von
                                    der amerikanischen Militärregierung.
                                </p>
                                <p className="copy">Abb. Bayerisches Hauptstaatsarchiv, BayHStA Stk 16610</p>
                            </div>
                            <figcaption>
                                Veranstaltungsprogramm der Europa-Union Bayern, 1948
                                <span className="description">
                                    Mit verschiedenen Vorträgen und Veranstaltungen verbreitete die Europa-Union die
                                    europäische Idee in der Bevölkerung. Finanzielle Unterstützung dafür kam auch von
                                    der amerikanischen Militärregierung.
                                </span>
                            </figcaption>
                        </figure>
                        <figure className="center">
                            <StaticImage src="../../../../static/images/Passau/silhouette-sterne.png" width={335}
                                         layout="fixed" placeholder="none" alt=""/>
                        </figure>
                        <br/>
                    </div>
                    <div className="col-6">
                        <figure className="lightbox">
                            <a className="glightbox" data-gallery="gallery2"
                               href={"/images/Passau/passau_bild_0010.jpg"}
                               data-glightbox="title: Der Bayerische Ministerpräsident Alfons Goppel zu Besuch beim Präsidenten der Kommission der Europäischen Wirtschaftsgemeinschaft, Walter Hallstein, 1963;
                            description: .custom-desc1_13; descPosition: bottom;">
                                <StaticImage src="../../../../static/images/Passau/passau_bild_0010.jpg" width={335}
                                             layout="fixed" placeholder="none"
                                             alt="Der Bayerische Ministerpräsident Alfons Goppel zu Besuch beim Präsidenten der Kommission der Europäischen Wirtschaftsgemeinschaft, Walter Hallstein, 1963"/>
                            </a>
                            <div className="glightbox-desc custom-desc1_13">
                                <p>
                                    Die Bayerische Staatsregierung suchte früh den Kontakt zur Europäischen
                                    Kommission.
                                    In seiner Zeit als Ministerpräsident (1962–1978) zählte es zu Goppels Zielen,
                                    die
                                    bayerische Eigenstaatlichkeit und die Prinzipien des Föderalismus und der
                                    Subsidiarität mit einem Konzept vom Europa der Regionen zu festigen.
                                </p>
                                <p className="quer">Abb. Foto: Marcelle Jamar / Communautés européennes</p>
                            </div>
                            <figcaption>
                                Der Bayerische Ministerpräsident Alfons Goppel zu Besuch beim Präsidenten der
                                Kommission
                                der Europäischen Wirtschaftsgemeinschaft, Walter Hallstein, 1963
                                <span className="description">
                                    Die Bayerische Staatsregierung suchte früh den Kontakt zur Europäischen Kommission.
                                    In seiner Zeit als Ministerpräsident (1962–1978) zählte es zu Goppels Zielen, die
                                    bayerische Eigenstaatlichkeit und die Prinzipien des Föderalismus und der
                                    Subsidiarität mit einem Konzept vom Europa der Regionen zu festigen.
                                </span>
                            </figcaption>
                        </figure>
                        <figure className="lightbox">
                            <a className="glightbox" data-gallery="gallery2"
                               href={"/images/Passau/passau_bild_0009.jpg"}
                               data-glightbox="title: Eugen Kogon (1903–1987) während seines Vortrags bei den Europäischen Wochen, 2. September 1952;
                            description: .custom-desc1_12; descPosition: bottom;">
                                <StaticImage src="../../../../static/images/Passau/passau_bild_0009.jpg" width={335}
                                             layout="fixed" placeholder="none"
                                             alt="Eugen Kogon (1903–1987) während seines Vortrags bei den Europäischen Wochen, 2. September 1952"/>
                            </a>
                            <div className="glightbox-desc custom-desc1_12">
                                <p>
                                    Der Publizist Eugen Kogon war als Gegner des Nationalsozialismus mehrere Jahre im KZ
                                    Buchenwald inhaftiert. Nach dem Krieg zählte er zu den führenden Intellektuellen der
                                    Bundesrepublik und engagierte sich besonders für ein geeintes Europa, unter anderem
                                    in der Union Europäischer Föderalisten. Von 1949 bis 1954 war er der Präsident der
                                    Europa-Union Deutschland. Am Rednerpult ist die grün-weiße Fahne der Europäischen
                                    Bewegung zu sehen.
                                </p>
                                <p className="copy">Abb. Stadtarchiv Passau, Fotosammlung EW 1952 / Geins</p>
                            </div>
                            <figcaption>
                                Eugen Kogon (1903–1987) während seines Vortrags bei den Europäischen Wochen, 2.
                                September 1952
                                <span className="description">
                                    Der Publizist Eugen Kogon war als Gegner des Nationalsozialismus mehrere Jahre im KZ
                                    Buchenwald inhaftiert. Nach dem Krieg zählte er zu den führenden Intellektuellen der
                                    Bundesrepublik und engagierte sich besonders für ein geeintes Europa, unter anderem
                                    in der Union Europäischer Föderalisten. Von 1949 bis 1954 war er der Präsident der
                                    Europa-Union Deutschland. Am Rednerpult ist die grün-weiße Fahne der Europäischen
                                    Bewegung zu sehen.
                                </span>
                            </figcaption>
                        </figure>
                        <br/>
                    </div>
                </Container.Row>
            </Container.More>
        </Container.Part>
    )
}

export default TextBlock4Parallax
