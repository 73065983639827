import * as React from 'react'
import * as Container from "../../container/container";

const TextBlock3Beige = (key) => {
    return (
        <Container.Part background="beige" key={key}>
            <Container.Row>
                <div className="col-12">

                        <h2>Bayern und Europa</h2>
                        <p>
                            In der frühen Nachkriegszeit gab es in der bayerischen Politik und Gesellschaft eine
                            intensive Auseinandersetzung mit der Idee eines vereinigten Europas. Parteien, Kirchen und
                            Verbände diskutierten darüber und propagierten ein föderal organisiertes, demokratisches
                            geeintes Europa. Im Jahr 1948 sprach sich der Bayerische Landtag in einem einstimmigen
                            Entschließungsantrag für die Schaffung der „Vereinigten Staaten von Europa“ aus. 1998 wurde
                            das Bekenntnis zur europäischen Einigung in die Staatsziele der Bayerischen Verfassung
                            aufgenommen.
                        </p>
                        <p>
                            Die Anfänge der heutigen Europäischen Union liegen in den 1950er-Jahren. Sechs Staaten
                            gründeten 1957 die Europäische Wirtschaftsgemeinschaft (EWG) mit der Absicht, durch
                            wirtschaftliche Verflechtungen künftig Konflikten entgegenzuwirken und den Wohlstand in
                            Europa zu fördern. Mit der Auflösung des Ostblocks 1989 änderten sich die Rahmenbedingungen.
                            1992 wurde die Europäische Union gegründet. Sie ist ein Zusammenschluss von (Stand Februar
                            2020) 27 demokratischen europäischen Staaten.
                        </p>

                </div>
            </Container.Row>
        </Container.Part>
    )
}

export default TextBlock3Beige
