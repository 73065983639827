import Headerimage from "./Headerimage";
import TextBlock1Blue from "./TextBlock1Blue";
import Novel from "./Novel";
import TextBlock2White from "./TextBlock2White";
import TextBlock3Beige from "./TextBlock3Beige";
import TextBlock4Parallax from "./TextBlock4Parallax";
import TextBlock5White from "./TextBlock5White";
import Video from "./Video";

const Parts = [
    Headerimage,
    TextBlock1Blue,
    Novel,
    TextBlock2White,
    TextBlock3Beige,
    TextBlock4Parallax,
    TextBlock5White,
    Video,
];

export {Parts};
