import * as React from 'react'
import * as Container from "../../container/container";
import { StaticImage } from "gatsby-plugin-image";

const Headerimage = (key, ortName) => {
    return (
        <Container.HeaderPart background={ortName} key={key}>
            <div className="headline">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h1>1952<br/> Passau <br/> <br/></h1>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="box">
                            <ul>
                                <li>Gemeinschaft</li>
                                <li>Föderalismus</li>
                                <li>Europa</li>
                            </ul>
                            <p>
                                Mit den Festspielen Europäische Wochen in Passau wurde 1952 ein Anstoß für den
                                europäischen Einigungsprozess gegeben.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="image-row">
                <div className="container">
                    <div className="row">
                        <div className="col-6">
                            <figure className="lightbox">
                                <a className="glightbox" data-gallery="gallery1"
                                   href={"/images/Passau/passau_bild_0012.jpg"}
                                   data-glightbox="title: Plakat der Europäischen Wochen, 1952; description: .custom-desc1_2; descPosition: bottom;">
                                    <StaticImage src="../../../../static/images/Passau/passau_bild_0012.jpg" width={335} layout="fixed" placeholder="none"
                                                 alt="Plakat der Europäischen Wochen, 1952"/>
                                </a>
                                <div className="glightbox-desc custom-desc1_2">
                                    <p>
                                        Die Gestaltung des Plakats zeigt den Anspruch der ersten Europäischen Wochen.
                                        Die antike Säule, an der eine Theatermaske und eine Harfe lehnen, steht für das
                                        gemeinsame kulturelle Erbe Europas. Im Zentrum steht die Fahne der Europäischen
                                        Bewegung mit den Flaggen der Einzelstaaten.
                                    </p>
                                    <p className="copy">Abb. Stadtarchiv Passau, Entwurf: Erwin Vogel</p>
                                </div>
                                <figcaption>
                                    Plakat der Europäischen Wochen, 1952
                                </figcaption>
                            </figure>
                        </div>
                        <div className="col-6">
                            <figure className="lightbox">
                                <a className="glightbox" data-gallery="gallery1"
                                   href={"/images/Passau/passau_bild_0007.jpg"}
                                   data-glightbox="title: Der Vortrag von Eugen Kogon in der Passauer Nibelungenhalle während der Europäischen Wochen, 1952; description: .custom-desc1_1; descPosition: bottom;">
                                    <StaticImage src="../../../../static/images/Passau/passau_bild_0007.jpg" width={335} layout="fixed" placeholder="none"
                                                 alt="Der Vortrag von Eugen Kogon in der Passauer Nibelungenhalle während der Europäischen Wochen, 1952"/>
                                </a>
                                <div className="glightbox-desc custom-desc1_1">
                                    <p>
                                        Über der Bühne stand das Motto der deutschen europäischen Bewegung: „Wir fordern
                                        die Vereinigten Staaten von Europa.“ Eugen Kogon, der Präsident der
                                        Europa-Union, hielt einen Vortrag mit dem Titel „Die Einigung Europas“ und
                                        forderte eine europäische Verfassung.
                                    </p>
                                    <p className="copy">Abb. Stadtarchiv Passau, Fotosammlung EW 1952 / Geins</p>
                                </div>
                                <figcaption>
                                    Der Vortrag von Eugen Kogon in der Passauer Nibelungenhalle während der Europäischen
                                    Wochen, 1952
                                </figcaption>
                            </figure>
                        </div>
                    </div>
                </div>
            </div>
        </Container.HeaderPart>
    )
}

export default Headerimage
