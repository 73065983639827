import * as React from 'react'
import * as Container from "../../container/container";
import { StaticImage } from "gatsby-plugin-image";

const TextBlock2White = (key) => {
    return (
        <Container.Part background="white" key={key}>
            <Container.Row>
                <div className="col-6">
                    <figure className="lightbox">
                        <a className="glightbox" data-gallery="gallery1"
                           href={"/images/Passau/passau_bild_0004.jpg"}
                           data-glightbox="title: Militärmarsch vor der Nibelungenhalle, 1973; description: .custom-desc1_3; descPosition: bottom;">
                            <StaticImage src="../../../../static/images/Passau/passau_bild_0004.jpg"  width={335} layout="fixed" placeholder="none"
                                         alt="Militärmarsch vor der Nibelungenhalle, 1973"/>
                        </a>
                        <div className="glightbox-desc custom-desc1_3">
                            <p>
                                An vielen Veranstaltungen in den Anfangsjahren der Festspiele waren Musiker der US-Armee
                                beteiligt. Die Militärkapelle spielte in der Stadt. Auch an der mehrfach ausverkauften
                                Aufführung der Oper „Die Hochzeit des Figaro“ im Jahr 1952 wirkten Musiker des
                                Symphonieorchesters der 7. US-Armee mit.
                            </p>
                            <p className="copy">Abb. Archiv Europäische Wochen</p>
                        </div>
                        <figcaption>
                            Militärmarsch vor der Nibelungenhalle, 1973
                            <span className="description">
                                An vielen Veranstaltungen in den Anfangsjahren der Festspiele waren Musiker der US-Armee
                                beteiligt. Die Militärkapelle spielte in der Stadt. Auch an der mehrfach ausverkauften
                                Aufführung der Oper „Die Hochzeit des Figaro“ im Jahr 1952 wirkten Musiker des
                                Symphonieorchesters der 7. US-Armee mit.
                            </span>
                        </figcaption>
                    </figure>
                </div>
                <div className="col-6">
                    <figure className="lightbox">
                        <a className="glightbox" data-gallery="gallery1"
                           href={"/images/Passau/passau_bild_0005.jpg"}
                           data-glightbox="title: Freilichtaufführung „Das Salzburger große Welttheater“ vor dem Passauer Dom, 1957; description: .custom-desc1_4; descPosition: bottom;">
                            <StaticImage src="../../../../static/images/Passau/passau_bild_0005.jpg" width={335} layout="fixed" placeholder="none"
                                         alt="Freilichtaufführung „Das Salzburger große Welttheater“ vor dem Passauer Dom, 1957"/>
                        </a>
                        <div className="glightbox-desc custom-desc1_4">
                            <p>
                                Neben Musikdarbietungen bilden Opern und Theateraufführungen einen festen Bestandteil
                                des Programms. Die Veranstaltungen finden oft auf Freilichtbühnen statt.
                            </p>
                            <p className="copy">Abb. Archiv Europäische Wochen</p>
                        </div>
                        <figcaption>
                            Freilichtaufführung „Das Salzburger große Welttheater“ vor dem Passauer Dom, 1957
                            <span className="description">
                                Neben Musikdarbietungen bilden Opern und Theateraufführungen einen festen Bestandteil
                                des Programms. Die Veranstaltungen finden oft auf Freilichtbühnen statt.
                            </span>
                        </figcaption>
                    </figure>
                </div>
            </Container.Row>
            <Container.More move="true" lessText="weniger lesen" moreText="weiter lesen">
                <Container.Row>
                    <div className="col-6">
                        <figure className="lightbox">
                            <a className="glightbox" data-gallery="gallery1"
                               href={"/images/Passau/passau_bild_0006.jpg"}
                               data-glightbox="title: Kuratoriumsvorsitzender Senator Gustav Haydn bei der Eröffnung der Europäischen Wochen, 1973; description: .custom-desc1_5; descPosition: bottom;">
                                <StaticImage src="../../../../static/images/Passau/passau_bild_0006.jpg" width={335} layout="fixed" placeholder="none"
                                             alt="Kuratoriumsvorsitzender Senator Gustav Haydn bei der Eröffnung der Europäischen Wochen, 1973"/>
                            </a>
                            <div className="glightbox-desc custom-desc1_5">
                                <p>
                                    Zu den Gästen zählen neben internationalen Künstlern immer auch führende Politiker.
                                    In der ersten Reihe ganz rechts ist hier der Mitbegründer der Europäischen Wochen
                                    Robert M. Allen zu sehen, neben ihm unter anderem Bundesminister Dr. Hans-Jochen
                                    Vogel und der bayerische Kultusminister Prof. Dr. Hans Maier.
                                </p>
                                <p className="copy">Abb. Archiv Europäische Wochen</p>
                            </div>
                            <figcaption>
                                Kuratoriumsvorsitzender Senator Gustav Haydn bei der Eröffnung der Europäischen Wochen,
                                1973
                                <span className="description">
                                    Zu den Gästen zählen neben internationalen Künstlern immer auch führende Politiker.
                                    In der ersten Reihe ganz rechts ist hier der Mitbegründer der Europäischen Wochen
                                    Robert M. Allen zu sehen, neben ihm unter anderem Bundesminister Dr. Hans-Jochen
                                    Vogel und der bayerische Kultusminister Prof. Dr. Hans Maier.
                                </span>
                            </figcaption>
                        </figure>
                        <figure className="lightbox">
                            <a className="glightbox" data-gallery="gallery1"
                               href={"/images/Passau/passau_bild_0013.jpg"}
                               data-glightbox="title:Programmheft der Europäischen Wochen, 1989; description: .custom-desc1_6; descPosition: bottom;">
                                <StaticImage src="../../../../static/images/Passau/passau_bild_0013.jpg" width={335} layout="fixed" placeholder="none"
                                             alt="Programmheft der Europäischen Wochen, 1989"/>
                            </a>
                            <div className="glightbox-desc custom-desc1_6">
                                <p className="copy">Abb. Archiv Europäische Wochen</p>
                            </div>
                            <figcaption>
                                Programmheft der Europäischen Wochen, 1989
                            </figcaption>
                        </figure>
                        <br/>
                    </div>
                    <div className="col-6">
                        <figure className="lightbox">
                            <a className="glightbox" data-gallery="gallery1"
                               href={"/images/Passau/passau_bild_0008.jpg"}
                               data-glightbox="title:Passau während der Festspiele, 2013; description: .custom-desc1_7; descPosition: bottom;">
                                <StaticImage src="../../../../static/images/Passau/passau_bild_0008.jpg" width={335} layout="fixed" placeholder="none"
                                             alt="Passau während der Festspiele, 2013"/>
                            </a>
                            <div className="glightbox-desc custom-desc1_7">
                                <p>
                                    Die Passauer Bevölkerung besuchte von Anfang an rege die Veranstaltungen der
                                    Festspiele. Aber auch zahlreiche Gäste kommen alljährlich nach Passau.
                                </p>
                                <p className="copy">Abb. Foto: Florian Weichselbaumer / Europäische Wochen</p>
                            </div>
                            <figcaption>
                                Passau während der Festspiele, 2013
                                <span className="description">
                                    Die Passauer Bevölkerung besuchte von Anfang an rege die Veranstaltungen der
                                    Festspiele. Aber auch zahlreiche Gäste kommen alljährlich nach Passau.
                                </span>
                            </figcaption>
                        </figure>
                        <figure className="lightbox">
                            <a className="glightbox" data-gallery="gallery1"
                               href={"/images/Passau/passau_bild_0011.jpg"}
                               data-glightbox="title: Die slowakische Opernsängerin Edita Gruberová bei den Europäischen Wochen, 2015; description: .custom-desc1_8; descPosition: bottom;">
                                <StaticImage src="../../../../static/images/Passau/passau_bild_0011.jpg" width={335} layout="fixed" placeholder="none"
                                             alt="Die slowakische Opernsängerin Edita Gruberová bei den Europäischen Wochen, 2015"/>
                            </a>
                            <div className="glightbox-desc custom-desc1_8">
                                <p>
                                    Renommierte europäische Künstlerinnen und Künstler wie auch regionale Berühmtheiten
                                    treten heute während der Europäischen Wochen in Kirchen, Klöstern, Burgen oder
                                    Schlössern sowie auf Plätzen unter freiem Himmel auf.
                                </p>
                                <p className="copy">Abb. Foto: Toni Scholz / Europäische Wochen</p>
                            </div>
                            <figcaption>
                                Die slowakische Opernsängerin Edita Gruberová bei den Europäischen Wochen, 2015
                                <span className="description">
                                    Renommierte europäische Künstlerinnen und Künstler wie auch regionale Berühmtheiten
                                    treten heute während der Europäischen Wochen in Kirchen, Klöstern, Burgen oder
                                    Schlössern sowie auf Plätzen unter freiem Himmel auf.
                                </span>
                            </figcaption>
                        </figure>
                        <br/>
                    </div>
                </Container.Row>
            </Container.More>
        </Container.Part>
    )
}

export default TextBlock2White
